$main_font:'Ubuntu', sans-serif;
$theme_color1:#edf1f5;
$accent_color:#fcaf17;
$theme_color2:#3292dc;
$white:#ffffff;

$header_color:#022662;
$font_color:#61718d;
$font_color2:#9cb2d6;
$font_color3:#8994aa;

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

body{
    font-family: $main_font;
    font-size: 14px;
    line-height: 24px;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,img{
        margin: 0;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
    max-width: 100%;
    height: auto;
}

.more_btn{
    color: $header_color;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    border-radius: 50px;
    background: $accent_color;
    @include anim;
    display: inline-block;
    text-transform: uppercase;
    padding: 20px 47px;
    text-decoration: none;
    transform: scale(1);
    @media only screen and (max-width: 1500px) {
        font-size: 11px;
        line-height:11px;
    }
    &:hover{
        transform: scale(1.15);
        text-decoration: none;
        color: $header_color;
    }
}

.table_box{
    display: table;
    @media only screen and (max-width: 991px) {
        display: block;
    }
    > div{
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        @media only screen and (max-width: 991px) {
            height: auto;
            display: block;
        }
    }
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            height: 100%;
            vertical-align: middle;
            display: table-cell;
        }
    }
}

.default_font{
    font-size: 16px;
    line-height: 30px;
    color: $main_font;
    @media only screen and (max-width: 1500px) {
        font-size: 13px;
        line-height: 27px;
    }
    &.scaled{
        font-size: 0.79vw;
        line-height: 1.6vw;
        h1{
            font-size: 2.80vw;
            line-height: 3.2vw;
        }
    }
    h1{
        font-size: 54px;
        line-height: 70px;
        @media only screen and (max-width: 1500px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    h2{
        font-size: 30px;
        line-height: 40px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    h3{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 1500px) {
            font-size: 22px;
            line-height: 32px;
        }
    }
    h4{
        font-size: 22px;
        line-height: 32px;
        @media only screen and (max-width: 1500px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    h5{
        font-size: 20px;
        line-height: 30px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 30px;
        }
    }
    p{
        strong{
            font-weight: 500;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
        color: $theme_color2;
        li{
            p{
                color: $header_color;
            }
        }
    }
    ol{
        padding-left: 18px;
    }
    a{
        @include anim();
        color: $accent_color;
        text-decoration: underline;
        &:hover{
            opacity: 0.8;
        }
    }
    strong{
        font-weight: 700;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,p{
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 15px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 991px) {
    .text-right{
        text-align: center;
    }
    .text-left{
        text-align: center;
    }
    .left_side{
        padding-left: 15px !important;
    }
    .right_side{
        padding-right: 15px !important;
    }
}

.asistance{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    @media only screen and (max-width: 991px) {
        width: 100%;
    }
    a{
        text-decoration: none;
    }
    .table_box{
        min-height: 66px;
        background: #022662;
        @media only screen and (max-width: 1500px) {
            min-height: 44px;
        }
        > div{
            @media only screen and (max-width: 991px) {
                display: inline-block;
                vertical-align: middle;
            }
            &.icon{
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('./../img/24h_icon.png');
                background-color: $accent_color;
                width: 66px;
                @media only screen and (max-width: 1200px) {
                    padding-bottom: 44px;
                    background-size: 40px;
                }
                @media only screen and (max-width: 991px) {
                    height: 0;
                    padding-bottom: 66px;
                }
            }
            &.desc{
                color:$white;
                font-size: 15px;
                line-height: 25px;
                text-align: center;
                font-weight: 700;
                padding: 0 21px;
                @media only screen and (max-width: 1200px) {
                    font-size: 13px;
                    line-height: 22px;
                }
                @media only screen and (max-width: 991px) {
                    width: calc(100% - 66px);
                    text-align: left;
                }
                p{
                    font-weight: inherit;
                }
                strong{
                    color: $accent_color;
                    font-size: 17px;
                    line-height: 17px;
                    font-weight: 700;
                    @media only screen and (max-width: 1200px) {
                        font-size: 15px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}

header.header{
    width: 100%;
    div{
        &.table_box{
            height: 145px;
            @media only screen and (max-width: 1500px) {
                height: 100px;
            }
            @media only screen and (max-width: 991px) {
                height: auto;
            }
        }
        &.logo_box{
           background: $white;
           width: 249px;
           @media only screen and (max-width: 1200px) {
                width: 180px;
                img{
                    max-width: 150px;
                    height: auto;;
                }
           }
           @media only screen and (max-width: 991px) {
                width: 100%;
                padding-top: 15px;
                padding-bottom: 15px;
                position: relative;
                div.rwd_btn{
                    width: 30px;
                    position: absolute;
                    top: 30px;
                    right: 0;
                    > div{
                        width: 100%;
                        height: 3px;
                        margin-bottom: 3px;
                        background: $accent_color;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.menu_box{
            padding-left: 65px;
            background: $theme_color1;
            position: relative;
            @media only screen and (max-width: 1200px) {
                padding-left: 37px;
            }
            @media only screen and (max-width: 991px) {
                padding-left: 0;
                margin-bottom: 15px;
                padding-top: 10px;
                padding-bottom: 10px;
                display: none;
            }
            .right_side{
                position: absolute;
                top: 0;
                left: 100%;
                height: 100%;
                display: block;
                background: $theme_color1;
                padding-left: 30px;
                @media only screen and (max-width: 991px) {
                    display: none;
                    padding-left: 0;
                }
            }
            nav.menu{
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 37px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 27px;
                        }
                        @media only screen and (max-width: 1200px) {
                            margin-right: 13px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                            text-align: center;
                            margin-bottom: 10px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        > div{
                            &.on{
                                > a{
                                    color:$theme_color2;
                                    &:after{
                                        left: 0;
                                        width: 100%;
                                    }
                                }
                            }
                            > a{
                                font-size: 13px;
                                line-height: 13px;
                                color: $header_color;
                                font-weight: 700;
                                text-decoration: none;
                                text-transform: uppercase;
                                @include anim;
                                position: relative;
                                display: inline-block;
                                @media only screen and (max-width: 1500px) {
                                    font-size: 11px;
                                    line-height: 11px;
                                }
                                &:after{
                                    content: '';
                                    width: 0%;
                                    left: 50%;
                                    border-bottom: 2px solid $theme_color2;
                                    margin-top: 5px;
                                    @include anim;
                                    position: absolute;
                                    top: 100%;
                                }
                                &:hover{
                                    color:$theme_color2;
                                    &:after{
                                        left: 0;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.bg{
            background: $theme_color1;
        }
    }
}

section.slider{
    position: relative;
    div.slides{
        @include fill;
        background: $header_color;
        > ul{
            @include fill;
            > li{
                @include fill;
                > div{
                    height: 100%;
                    position: absolute;
                    top: 0;
                    &.slider_left{
                        width: 63%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        div.slider_content{
                            padding-top: 3vw;
                            padding-right: 6%;
                            height: 75%;
                            width: 100%;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                            background-image: url('./../img/sl_content_bg.jpg');
                            @media only screen and (max-width: 991px) {
                                padding-top: 100px;
                                text-align: center;
                            }
                            div.desc{
                                margin-bottom: 2vw;
                                .icon{
                                    font-size: 1vw;
                                    color: $font_color2;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    background-size: auto 100%;
                                    width: 100%;
                                    min-height: 60px;
                                    padding-left: 13%;
                                    padding-top: 1.3vw;
                                    font-weight: 500;
                                    margin-bottom: 2%;
                                    @media only screen and (max-width: 1500px) {
                                        min-height: 50px;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        padding-left: 14%;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        font-size: 20px;
                                        min-height: auto;
                                        text-align: center;
                                        padding-top: 80px;
                                        background-position: center top;
                                        padding-left: 0;
                                        margin-bottom: 30px;
                                        background-size: auto;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                }
                                header{
                                    margin-bottom: 3%;
                                    div.default_font{
                                        h1{
                                            color: $white;
                                        }
                                    }
                                }
                                div.default_font{
                                    color: $font_color2;
                                }
                            }
                            a.more_btn{
                                position: relative;
                                z-index: 2;
                            }
                        }
                    }
                    &.slider_right{
                        width: 37%;
                        left: 63%;
                        background: $theme_color2;
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                        div.slider_image{
                            @include fill;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                }
            }
        }
        > .slider_nav{
            width: 63%;
            height: 75%;
            position: absolute;
            top: 0;
            left: 0;
            @media only screen and (max-width: 991px) {
                width: 100%;
                height: 100%;
                text-align: center;
            }
            div{
                &.table_box{
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 2.5vw;
                    padding-right: 6%;
                }
                &.slider_dots{
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            color: #3f6199;
                            @include anim;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 18px;
                            padding-right: 27px;
                            position: relative;
                            margin-right: 22px;
                            cursor: pointer;
                            &.on{
                                color: $white;
                                &:after{
                                    background: $white;
                                }
                            }
                            &:hover{
                                color: $white;
                                &:after{
                                    background: $white;
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            &:after{
                                @include anim;
                                content: '';
                                width: 20px;
                                height: 2px;
                                background: #3f6199;
                                position: absolute;
                                top: 8px;
                                right: 0;
                            }
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
                &.arrs{
                    width: 70px;
                    margin: 20px auto;
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        width: 50%;
                        img{
                            max-width: 100%;
                            height: auto;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    div.fast_contact{
        background: $theme_color1;
        height: 25%;
        width: 63%;
        position: absolute;
        left: 0;
        bottom: 0;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        div{
            &.contact{
                ul{
                    li{
                        display: inline-block;
                        vertical-align: top;
                        min-height: 33.33%;
                        background-repeat: no-repeat;
                        background-position: left top 5px;
                        padding-left: 10%;
                        color: $header_color;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 30px;
                        @media only screen and (max-width: 1500px) {
                            font-size: 13px;
                            line-height: 27px;
                            padding-left: 13%;
                        }
                        p{
                            font-weight: inherit;
                        }
                        &.adress{
                            background-image: url('./../img/contact_icon1.png');
                            width: 40%;
                        }
                        &.open{
                            background-image: url('./../img/contact_icon2.png');
                        }
                    }
                }
            }
            &.scroll_down{
                cursor: pointer;
                width: 21%;
                background: $white;
                text-align: center;
            }
        }
    }
}

.section_header{
    margin: 40px 0;
    text-align: center;
    &.icon{
        margin-top: 0;
        margin-bottom: 40px;
        padding-top: 100px;
        background-position: center top;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1500px) {
            margin-top: 30px;
        }
    }
    &.icon2{
        text-align: left;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        > div{
            display: inline-block;
            vertical-align: middle;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &.icon{
                margin-right: 40px;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
            &.default_font{
                margin-bottom: 0 !important;
            }
        }
    }
    div.default_font{
        h1,h2,h3,h4,h5,h6{
            color: $header_color;
        }
    }
    u{
        position: relative;
        text-decoration: none;
        &:after{
            content: '';
            top: 100%;
            left: 0;
            width: 100%;
            height: 2px;
            background: $theme_color2;
            position: absolute;
            margin-top: 4px;
        }
    }
}

section.short_about{
    padding: 60px 0;
    @media only screen and (max-width: 1500px) {
        padding:0 0 30px 0;
    }
    div.desc{
        text-align: center;
        margin-bottom: 60px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 1200px) {
            margin-bottom: 30px;
        }
        div.default_font{
            color: $header_color;
        }
    }
    div.offer_list{
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 25%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &:hover{
                    article{
                        -webkit-box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        -moz-box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        header{
                           div.icon{
                            &.icon1{
                                visibility: hidden;
                                opacity: 0;
                            }
                            &.icon2{
                                visibility: visible;
                                opacity: 1;
                            }
                           }
                        }
                    }
                }
                article{
                    padding: 35px 30px;
                    @include anim();
                    border-radius: 5px;
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    header{
                        margin-bottom: 40px;
                        @media only screen and (max-width: 1500px) {
                            margin-bottom: 20px;
                        }
                        div.icon_holder{
                            position: relative;
                            height: 80px;
                            width: 100%;
                        }
                        div.icon{
                            @include fill;
                            background-size: auto 100%;
                            background-repeat: no-repeat;
                            background-position: left bottom;
                            margin-bottom: 37px;
                            @include anim;
                            &.icon1{
                                visibility: visible;
                                opacity: 1;
                            }
                            &.icon2{
                                visibility: hidden;
                                opacity: 0;
                            }
                            @media only screen and (max-width: 991px) {
                                background-position: center bottom;
                            }
                        }
                        div.default_font{
                            color: $header_color;
                        }
                    }
                    div.short_desc{
                        div{
                            color: $font_color;
                            font-weight: 500;
                            line-height: 30px;
                            @media only screen and (max-width: 1500px) {
                                font-size: 13px;
                                line-height: 27px;
                            }
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
                /*
                &.o1{
                    article{
                        header{
                            div.icon{
                                background-image: url('./../img/o_icon1.png');
                            }
                        }
                    }
                    &:hover{
                        article{
                            header{
                                div.icon{
                                    background-image: url('./../img/o_icon1a.png');
                                }
                            }
                        }
                    }
                }
                &.o2{
                    article{
                        header{
                            div.icon{
                                background-image: url('./../img/o_icon2.png');
                            }
                        }
                    }
                    &:hover{
                        article{
                            header{
                                div.icon{
                                    background-image: url('./../img/o_icon2a.png');
                                }
                            }
                        }
                    }
                }
                &.o3{
                    article{
                        header{
                            div.icon{
                                background-image: url('./../img/o_icon3.png');
                            }
                        }
                    }
                    &:hover{
                        article{
                            header{
                                div.icon{
                                    background-image: url('./../img/o_icon3a.png');
                                }
                            }
                        }
                    }
                }
                &.o4{
                    article{
                        header{
                            div.icon{
                                background-image: url('./../img/o_icon4.png');
                            }
                        }
                    }
                    &:hover{
                        article{
                            header{
                                div.icon{
                                    background-image: url('./../img/o_icon4a.png');
                                }
                            }
                        }
                    }
                }*/
            }
        }
    }
    div.certifications_list{
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 22.7%;
                height: 0;
                padding-bottom: 32.35%;
                position: relative;
                overflow: hidden;
                margin-right: 3%;
                margin-bottom: 3%;
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 20px;
                    padding-bottom: 142%;
                }
                &:nth-child(4n), &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    .img{
                        transform: scale(1.1);
                        .cover{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                .img{
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    @include fill;
                    @include anim;
                    .cover{
                        @include anim;
                        @include fill;
                        background: rgba(2,38,98,0.8);
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
        }
    }
    .main_image{
        -webkit-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
        -moz-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
        box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 0;
        padding-bottom: 42.4%;
        margin-bottom: 100px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 1200px) {
            margin-bottom: 20px;
        }
    }
    .icon_descs{
        ul{
            li{
                width: 100%;
                display: flex;
                flex-flow: row;
                margin-bottom: 100px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 50px;
                }
                @media only screen and (max-width: 991px) {
                    flex-flow: column;
                    margin-bottom: 20px;
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 0;
                }
                &:last-child{
                    margin-bottom: 60px;
                    @media only screen and (max-width: 1500px) {
                        margin-bottom: 0;
                    }
                }
                &:nth-child(odd){
                    > div{
                        &.icon{
                            order: 2;
                            @media only screen and (max-width: 991px) {
                                order: 1;
                                margin: 0 auto;
                            }
                        }
                        &.desc{
                            order: 1;
                            text-align: left;
                            padding-right: 100px;
                            @media only screen and (max-width: 1500px) {
                                padding-right: 50px;
                            }
                            @media only screen and (max-width: 991px) {
                                order: 2;
                                text-align: center;
                                padding-right: 0;
                            }
                        }
                    }
                }
                &:nth-child(even){
                    > div{
                        &.icon{
                            order: 1;
                            @media only screen and (max-width: 991px) {
                                order: 1;
                                margin: 0 auto;
                            }
                        }
                        &.desc{
                            order: 2;
                            text-align: left;
                            padding-left: 100px;
                            @media only screen and (max-width: 1500px) {
                                padding-left: 50px;
                            }
                            @media only screen and (max-width: 991px) {
                                order: 2;
                                text-align: center;
                                padding-left: 0;
                            }
                        }
                    }
                }
                > div{
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 991px) {
                        align-items: stretch;
                    }
                    &.icon{
                        width: 200px;
                        height: 0;
                        padding-bottom: 200px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        @media only screen and (max-width: 1500px) {
                            height: 170px;
                            padding-bottom: 170px;
                        }
                        @media only screen and (max-width: 1200px) {
                            width: 100px;
                            padding-bottom: 100px;
                        }

                    }
                    &.desc{
                        width: calc(100% - 200px);
                        padding-top: 20px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

section.banner{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('./../img/banner_bg.jpg');
    @media only screen and (max-width: 991px) {
        padding-bottom: 50px;
    }
    .section_header{
        div.default_font{
            color: $white;
            h1,h2,h3,h4,h5,h6{
                color: inherit;
            }
        }
    }
    .table_box{
        width: 100%;
        min-height: 200px;
        @media only screen and (max-width: 991px) {
            min-height: auto;
        }
        div{
            &.list{
                padding: 0 30px;
                width: 50%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
}

.list{
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    ul{
        li{
            color: $font_color2;
            padding-left: 37px;
            min-height: 20px;
            background-repeat: no-repeat;
            background-position: left top 5px;
            background-image: url('./../img/chceck_icon.png');
            margin-bottom: 28px;
            @media only screen and (max-width: 1500px) {
                font-size: 13px;
                line-height: 27px;
                margin-bottom: 15px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

section.features{
    width: 100%;
    min-height: 580px;
    > div{
        width: 33.33%;
        vertical-align: top;
        padding-top: 80px;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        position: relative;
        padding-left: 50px;
        padding-right: 50px;
        @media only screen and (max-width: 1500px) {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media only screen and (max-width: 991px) {
            width: 100%;
            padding-top: 40px;
            padding-bottom: 40px;
            text-align: center;
        }
        .box_content{
            position: relative;
            z-index: 2;
            div{
                &.logo{
                    margin-bottom: 60px;
                    img{
                        max-width: 100%;
                        height: auto;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                        text-align: center;
                    }
                }
            }
            .section_header{
                div.default_font{
                    h1,h2,h3,h4,h5,h6{
                        position: relative;
                        &:after{
                            content: '';
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url('./../img/header_check.png');
                            width: 20px;
                            height: 25px;
                            display: inline-block;
                            margin-left: 14px;
                        }
                    }
                }
            }
        }
        &.side_left{
            color: $white;
            //padding-right: 50px;
            //@media only screen and (max-width: 991px) {
            //    padding-right: 15px;
            //}
            .section_header{
                div.default_font{
                    color: $white;
                    h1,h2,h3,h4,h5,h6{
                        color: inherit;
                    }
                }
            }
            div.cover{
                @include fill;
                background-repeat: no-repeat;
                background-position: left top;
                background-size: cover;
                background-image: url('./../img/box_left_cover.png');
            }
        }
        &.side_right{
            //padding-left: 50px;
            //@media only screen and (max-width: 991px) {
            //    padding-left: 15px;
            //}
            color: $header_color;
        }
    }
}

//
//section.features{
//    width: 100%;
//    min-height: 580px;
//    > div{
//        width: 50%;
//        vertical-align: top;
//        padding-top: 80px;
//        background-repeat: no-repeat;
//        background-position: center bottom;
//        background-size: cover;
//        position: relative;
//        @media only screen and (max-width: 991px) {
//            width: 100%;
//            padding-top: 40px;
//            padding-bottom: 40px;
//            text-align: center;
//        }
//        .box_content{
//            position: relative;
//            z-index: 2;
//            div{
//                &.logo{
//                    margin-bottom: 60px;
//                    img{
//                        max-width: 100%;
//                        height: auto;
//                    }
//                    @media only screen and (max-width: 991px) {
//                        margin-bottom: 30px;
//                        text-align: center;
//                    }
//                }
//            }
//            .section_header{
//                div.default_font{
//                    h1,h2,h3,h4,h5,h6{
//                        position: relative;
//                        &:after{
//                            content: '';
//                            background-repeat: no-repeat;
//                            background-position: center;
//                            background-image: url('./../img/header_check.png');
//                            width: 20px;
//                            height: 25px;
//                            display: inline-block;
//                            margin-left: 14px;
//                        }
//                    }
//                }
//            }
//        }
//        &.left_side{
//            color: $white;
//            padding-right: 50px;
//            @media only screen and (max-width: 991px) {
//                padding-right: 15px;
//            }
//            .section_header{
//                div.default_font{
//                    color: $white;
//                    h1,h2,h3,h4,h5,h6{
//                        color: inherit;
//                    }
//                }
//            }
//            div.cover{
//                @include fill;
//                background-repeat: no-repeat;
//                background-position: left top;
//                background-size: cover;
//                background-image: url('./../img/box_left_cover.png');
//            }
//        }
//        &.right_side{
//            padding-left: 50px;
//            @media only screen and (max-width: 991px) {
//                padding-left: 15px;
//            }
//            color: $header_color;
//        }
//    }
//}

section.how_we_work{
    //padding-bottom: 100px;
    div.line{
        margin-bottom: 100px;
        width: 100%;
        height: 5px;
        background: #d8dee9;
        margin-top: -14px;
        position: relative;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        > div{
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: $theme_color2;
            @include anim;
        }
    }
    div.step_list{
        margin:0 -30px;
        position: relative;
        z-index: 2;
        @media only screen and (max-width: 991px) {
            margin: 0;
        }
        ul{
            padding-bottom: 22px;
            display: table;
            width: 100%;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            li{
                display: table-cell;
                vertical-align: top;
                width: 20%;
                padding: 0 30px;
                position: relative;
                &.hovered{
                    div.image{
                        > div.img{
                            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                            filter: grayscale(0%);
                            opacity: 1;
                        }
                        > div.step{
                            color: $white;
                            background: $theme_color2;
                        }
                    }
                    div.point{
                        -webkit-box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        -moz-box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        > div{
                            background: $theme_color2;
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding: 0;
                    display: block;
                    margin-bottom: 30px;
                }
                div.point{
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                    width: 22px;
                    height: 0;
                    padding-bottom: 22px;
                    border-radius: 50%;
                    position: absolute;
                    //top: 100%;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 50%;
                    background: $white;
                    -webkit-box-shadow: 0px 0px 20px 10px rgba(237,237,237,0.8);
                    -moz-box-shadow: 0px 0px 20px 10px rgba(237,237,237,0.8);
                    box-shadow: 0px 0px 20px 10px rgba(237,237,237,0.8);
                    @include anim;
                    > div{
                        position: absolute;
                        height: 0;
                        padding-bottom: 10px;
                        width: 10px;
                        top: 6px;
                        left: 6px;
                        background: #b2bcc6;
                         border-radius: 50%;
                        @include anim;
                    }
                }
                &:hover{
                    div.image{
                        > div.img{
                            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                            filter: grayscale(0%);
                            opacity: 1;
                        }
                        > div.step{
                            color: $white;
                            background: $theme_color2;
                        }
                    }
                    div.point{
                        -webkit-box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        -moz-box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        box-shadow: 0px 0px 10px 10px rgba(32,103,191,0.1);
                        > div{
                            background: $theme_color2;
                        }
                    }
                }
                div.image{
                    background: #949aa4;
                    border-radius: 7px;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    margin-bottom: 60px;
                    position: relative;
                    > div.img{
                        @include anim;
                        @include fill;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        border-radius: 7px;
                         opacity: 0.5;
                        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                        filter: grayscale(100%);
                    }
                    > div.step{
                        z-index: 2;
                        width: 70px;
                        height: 0;
                        padding-bottom: 70px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 100%;
                        margin-top: -40px;
                        margin-left: auto;
                        margin-right: auto;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 36px;
                        line-height: 36px;
                        @include anim;
                        font-weight: 700;
                        background: #eaedf3;
                        color: #adb6c5;
                        @media only screen and (max-width: 1500px) {
                            font-size: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
    .desc_place{
        min-height: 160px;
        padding: 0 100px;
        @media only screen and (max-width: 1500px) {
            font-size: 13px;
            line-height: 27px;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
    .hidden_desc{
        height: 0;
        @media only screen and (max-width: 991px) {
            height: auto;
            text-align: center;
        }
        > div{
            visibility: hidden;
            opacity: 0;
            @media only screen and (max-width: 991px) {
                visibility: visible;
                opacity: 1;
            }
        }
        header{
            margin-bottom: 20px;
        }
        div.desc{
            color: $font_color3;
        }
    }
}

footer.footer{
    @media only screen and (max-width: 991px) {
        table{
            width: 100% !important;
             td{
                 vertical-align: top;
                width: 50% !important;
                text-align: left;
            }
        }
    }
    div{
        &.partenrs{
            padding: 60px 0;
            background: $theme_color1;
            @media only screen and (max-width: 1500px) {
                padding: 30px 0;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            .default_font{
                h1,h2,h3,h4,h5,h6{
                    color: $header_color;
                }
                @media only screen and (max-width: 1200px) {
                    h3{
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
            .arrs2{
                padding-top: 20px;
                @media only screen and (max-width: 1200px) {
                    padding-top: 10px;
                }
                > div{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 33px;
                    cursor: pointer;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .carousel1{
                overflow: hidden;
                > ul{
                    white-space: nowrap;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        margin-top: 20px;
                    }
                    > li{
                        display: inline-block;
                        vertical-align: middle;
                        width: 33.33%;
                        text-align: center;
                        @media only screen and (max-width: 1200px) {
                            width: 50%;
                            img{
                                height: 40px;
                                width: auto;
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.footer_top{
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('./../img/footer_bg.jpg');
            padding: 100px 0 70px 0;
            min-height: 630px;
            width: 100%;
            color: $font_color3;
            @media only screen and (max-width: 1500px) {
                padding: 50px 0 45px 0;
                min-height: auto;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            div.default_font{
                color: $white;
                font-size: 14px;
                strong{
                    font-weight: 700;
                }
                h1,h2,h3,h4,h5,h6{
                    color: inherit;
                }
            }
            div.desc{
                margin-bottom: 30px;
                p{
                    a{
                        color: $theme_color2;
                    }
                }
            }
        }
        &.footer_bottom{
            padding: 42px 0;
            background: #0d1c36;
            color: $font_color3;
            font-weight: 500;
            @media only screen and (max-width: 991px) {
                text-align: center;
                padding: 20px 0;
            }
            ul{
                padding-top: 10px;
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 60px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                    a{
                        color: $theme_color2;
                        text-decoration: underline;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .text-right{
                > ul{
                    > li{
                        margin-right: 18px;
                    }
                }
            }
            .to_up{
                display: inline-block;
                cursor: pointer;
                color: #576581;
                font-size: 12px;
                line-height: 12px;
                padding-top: 24px;
                background-position: center top;
                background-repeat: no-repeat;
                background-image: url('./../img/to_up_arr.png');
            }
        }
    }
}

form{
    .row{
        margin: 0 -20px;
        > div{
            padding: 0 20px;
        }
    }
    div.holder{
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $font_color3;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            color: $font_color3;
          }
          :-ms-input-placeholder { /* IE 10+ */
            color: $font_color3;
          }
          :-moz-placeholder { /* Firefox 18- */
            color: $font_color3;
          }
          margin-bottom: 50px;
          &.text-right{
              margin-bottom: 0;
          }
        input{
            outline: none;
            &.input{
                width: 100%;
                color: $font_color2;
                border-top:none;
                border-left:none;
                border-right:none;
                border-bottom: 2px solid #3b527e;
                height: 42px;
                background: transparent;
                &.c_input{
                    border: none;
                    background: $theme_color1;
                    height: 58px;
                    font-size: 16px;
                    line-height: 16px;
                    padding: 0 25px;
                }
            }
            &.submit{
                width: 220px;
                height: 54px;
                border: none;
                color: $white;
                font-weight: 700;
                font-size: 13px;
                line-height: 13px;
                border-radius: 50px;
                background: #3b527e;
                @include anim;
                text-transform: uppercase;
                text-decoration: none;
                transform: scale(1);
                &.c_submit{
                    background: $accent_color;
                    color: $header_color;
                }
                &:hover{
                    transform: scale(1.15);
                    text-decoration: none;
                    color: $white;
                }
            }
        }
        textarea{
            width: 100%;
            color: $font_color2;
            border-top:none;
            border-left:none;
            border-right:none;
            border-bottom: 2px solid #3b527e;
            height: 42px;
            background: transparent;
            resize: none;
            outline: none;
            padding-top: 8px;
            &.c_textarea{
                border: none;
                background: $theme_color1;
                height: 200px;
                font-size: 16px;
                line-height: 16px;
                padding: 25px;
            }
        }
    }
}

section.contact{
    div.map{
        margin-top: 30px;
        width: 100%;
        position: relative;
        height: 300px;
        > iframe{
            @include fill;
            border: none;
        }
    }
    form{
        input{
            outline: none;
            &.input{
                width: 100%;
                color: $font_color2;
                border: none;
                background: $theme_color1;
                height: 58px;
                font-size: 16px;
                line-height: 16px;
                padding: 0 25px;
            }
            &.submit{
                width: 220px;
                height: 54px;
                border: none;
                font-weight: 700;
                font-size: 13px;
                line-height: 13px;
                border-radius: 50px;
                background: $accent_color;
                color: $header_color;
                @include anim;
                text-transform: uppercase;
                text-decoration: none;
                transform: scale(1);
                &:hover{
                    transform: scale(1.15);
                    text-decoration: none;
                    color: $white;
                }
            }
        }
        textarea{
            width: 100%;
            color: $font_color2;
            resize: none;
            outline: none;
            border: none;
            background: $theme_color1;
            height: 200px;
            font-size: 16px;
            line-height: 16px;
            padding: 25px;
            &:focus{
                @include anim;
                background: $white;
                -webkit-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
                -moz-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
                box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
            }
        }
    }
}

section.top{
    height: 240px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('./../img/top_bg.jpg');
    position: relative;
    padding-top: 55px;
    @media only screen and (max-width: 1500px) {
        height: 200px;
        padding-top: 40px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 80px;
        text-align: center;
    }
    header.page_header{
        margin-bottom: 50px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
        div.default_font{
            color: $white;
            h1,h2,h3,h4,h5,h6{
                color: $white;
            }
            h1{
                font-size: 40px;
                line-height: 56px;
                @media only screen and (max-width: 1500px) {
                    font-size: 30px;
                    line-height: 40px;
                }
            }
            @media only screen and (max-width: 991px) {
               h1{
                font-size: 20px;
               }
            }
        }
    }
    div.nav_way{
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                div.on{
                    a{
                        font-weight: 500;
                        text-decoration: underline;
                    }
                }
                &:last-child{
                    a{
                        margin-right: 0;
                        padding-right:0;
                        &:after{
                            display: none;

                        }
                    }
                }
                a{
                    color: #9cb2d6;
                    font-size: 16px;
                    line-height: 16px;
                    padding-right: 22px;
                    position: relative;
                    text-decoration: none;
                    display: inline-block;
                    margin-right: 13px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 13px;
                        line-height: 13px;
                    }
                    &:after{
                        content: '/';
                        color: #43587c;
                        position: absolute;
                        top: 0;
                        right: 0;

                    }
                }
            }
        }
    }
    div.fast_contact{
        padding-left: 19%;
        @media only screen and (max-width: 1500px) {
            padding-left: 0;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }
        .contact{
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    background-repeat: no-repeat;
                    background-position: left top 5px;
                    padding-left: 66px;
                    color: $white;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 30px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 13px;
                        line-height: 26px;
                        background-size: auto 30px;
                        padding-left: 40px;
                    }
                    p{
                        font-weight: inherit;
                    }
                    &.adress{
                        background-image: url('./../img/contact_icon1.png');
                        margin-bottom: 30px;
                        @media only screen and (max-width: 1500px) {
                            margin-bottom: 15px;
                        }
                    }
                    &.open{
                        background-image: url('./../img/contact_icon2.png');
                    }
                }
            }
        }
    }
}

section.contact{
    padding: 60px 0;
    @media only screen and (max-width: 1200px) {
        padding: 30px 0;
    }
    > .container{
        > .row{
            margin: 0 -80px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -50px
            }
            @media only screen and (max-width: 1200px) {
                margin: 0 -30px;
            }
            > div{
                padding: 0 80px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 50px;
                }
                @media only screen and (max-width: 1200px) {
                    padding: 0 30px;
                }
            }
        }
    }
    .section_header{
        margin-top: 0;
    }
    .boxes{
        -webkit-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
        -moz-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
        box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    div.contact_data{
        font-size: 16px;
        line-height: 25px;
        color: $header_color;
        padding:30px 40px;
        @media only screen and (max-width: 1500px) {
            font-size: 14px;
            line-height: 23px;
        }
        &:last-child{
            -webkit-box-shadow: inset 0px 10px 25px 0px rgba(32,103,191,0.1);
            -moz-box-shadow: inset 0px 10px 25px 0px rgba(32,103,191,0.1);
            box-shadow: inset 0px 10px 25px 0px rgba(32,103,191,0.1);
            background: #f4f6f8;
        }
        header{
            font-size: 18px;
            line-height: 28px;
            color: $header_color;
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
        }
        ul.main_contact_list{
            margin-bottom: 30px;
            li{
                background-repeat: no-repeat;
                background-position: left center;
                margin-bottom: 25px;
                min-height: 30px;
                padding-left: 40px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 20px;
                }
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    padding-top: 40px;
                    background-position: center top;
                    text-align: center;
                    width: 100%;
                }
                a{
                    color: $header_color;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                &.adres{
                    background-image: url('./../img/contact_small_icon1.png');
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        &:last-child{
                            padding-left: 50px;
                            @media only screen and (max-width: 991px) {
                                padding-left: 0;
                                padding-top: 15px;
                                width: 100%;
                            }
                        }
                    }
                }
                &.phone{
                    background-image: url('./../img/contact_small_icon2.png');
                }
                &.mail{
                    background-image: url('./../img/contact_small_icon3.png');
                }
                &.open{
                    background-image: url('./../img/contact_small_icon4.png');
                }
            }
        }
    }
    .members{
        > ul{
            margin: 0 -15px;
           > li{
                display: inline-block;
                vertical-align: top;
                padding: 0 15px;
                width: 33.33%;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                header{
                    height: 28px;
                }
                .member_bottom{
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        &.image{
                            width: 150px;
                            height: 180px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                            @media only screen and (max-width: 1200px) {
                                width: 100px;
                                height: 130px;
                            }
                            @media only screen and (max-width: 991px) {
                                display: block;
                                margin: 0 auto 15px auto;
                            }
                        }
                        &.desc{
                            padding-left: 15px;
                            width: calc(100% - 150px);
                            padding-top: 15px;
                            @media only screen and (max-width: 1200px) {
                                width:calc(100% - 100px);
                            }
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                text-align: center;
                            }
                            h2{
                                font-size: 20px;
                                line-height: 30px;
                                margin-bottom: 10px;
                            }
                            a{
                                color: $header_color;
                            }
                        }
                    }
                }
            }
        }
    }
    div.holder{
        margin-bottom: 20px;
        .input{
            &:focus{
                @include anim;
                background: $white;
                -webkit-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
                -moz-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
                box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
            }
        }
        .c_textarea{
            &:focus{
                @include anim;
                background: $white;
                -webkit-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
                -moz-box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
                box-shadow: 0px 0px 25px 0px rgba(32,103,191,0.1);
            }
        }
        .c_submit{
            margin-top: 15px;
        }
    }
    div.members{
        margin-top: 20px;
        padding-top: 40px;
        border-top: 1px solid #d9d9d9;
        @media only screen and (max-width: 1200px) {
            padding-top: 20px;
            margin-top: 30px;
        }
        div.members_list{
            ul{
                li{
                    header{
                        margin-bottom: 15px;
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 700;
                        @media only screen and (max-width: 1500px) {
                            font-size: 15px;
                            line-height: 25px;
                        }
                        p{
                            font-weight: inherit;
                        }
                    }
                    display: inline-block;
                    vertical-align: top;
                    width: 25%;
                    color: #596983;
                    font-size: 16px;
                    line-height: 30px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 13px;
                        line-height: 27px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

section.galery_slides{
    > ul{
        > li{
            width: 100%;
            margin-bottom: 44px;
            @media only screen and (max-width: 991px) {
                display: flex;
                flex-flow: column;
            }
            &:nth-child(odd){
                > div{
                    &.desc{
                        padding-right: 80px;
                        @media only screen and (max-width: 1500px) {
                            padding-right: 50px;
                        }
                        @media only screen and (max-width: 991px) {
                            padding-right: 15px;
                        }
                    }
                    &.slides{
                        > div.arrs3{
                            left: 0;
                        }
                    }
                }
            }
            &:nth-child(even){
                > div{
                    &.desc{
                        padding-top: 0;
                        padding-left: 80px;
                        @media only screen and (max-width: 1500px) {
                            padding-left: 50px;
                        }
                        @media only screen and (max-width: 991px) {
                            padding-left: 15px;
                        }
                    }
                    &.slides{
                        > div.arrs3{
                            right: 0;
                        }
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
                > div{
                    &.desc{
                        //padding-top: 0;
                    }
                }
            }
            > div{
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    align-items: stretch;
                }
                &.desc{
                    width: 55%;
                    padding-top: 80px;
                    @media only screen and (max-width: 1500px) {
                        padding-top: 50px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-top: 30px;
                        order: 2;
                    }
                    div.default_font{
                        color: $header_color;
                        margin-bottom: 50px;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                        }
                    }
                }
                &.slides{
                    width: 45%;
                    height: 0;
                    padding-bottom: 495px;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        order: 1;
                        padding-bottom: 300px;
                    }
                    > ul{
                        @include fill;
                        > li{
                            @include fill;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                    .arrs3{
                        position: absolute;
                        bottom: 0;
                        > div{
                            background-color: rgba(255,255,255,0.8);
                            width: 50px;
                            height: 0;
                            padding-bottom: 50px;
                            background-repeat: no-repeat;
                            background-position: center;
                            display: inline-block;
                            vertical-align: top;
                            @include anim;
                            cursor: pointer;
                            &:hover{
                                background-color: rgba(255,255,255,1);
                            }
                            &.arr_left{
                                background-image: url('./../img/sl_arr_left.png');
                            }
                            &.arr_right{
                                background-image: url('./../img/sl_arr_right.png');
                            }
                        }
                    }
                }
            }
        }
    }
}

.call{
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 51px;
    font-weight: 700;
    color: $header_color;
    @media only screen and (max-width: 991px) {
        text-align: center;
        font-size: 18px;
        line-height: 38px;
    }
    strong{
        background: $theme_color2;
        color: $white;
        display: inline-block;
        font-size: 30px;
        line-height: 30px;
        padding: 10px 14px;
        @media only screen and (max-width: 991px) {
            font-size: 20px;
            line-height: 20px;
        }
    }
}

section.warranty{
    height: 500px;
    width: 100%;
    position: relative;
    color: $white;
    @media only screen and (max-width: 1500px) {
        height: 400px;
    }
    @media only screen and (max-width: 1200px) {
        height: 350px;
    }
    div.desc{
        margin-bottom: 50px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
        h2{
            font-size: 54px;
            line-height: 64px;
            margin-bottom: 15px;
            @media only screen and (max-width: 1500px) {
                font-size: 30px;
                line-height: 40px;
            }
        }
        font-size: 30px;
        line-height:50px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
}

.page{
    overflow: hidden;
}
